import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { FBLogin, Login, User } from 'src/app/models/user';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { UserRegistrationComponent } from '../user-registration/user-registration.component';
import { CredentialResponse , PromptMomentNotification} from 'google-one-tap'; //

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css',
    '../../../assets/css/login_modal.css']
})
export class UserLoginComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;
  showOtpForm: boolean;
  userSubmitted: boolean;
  @Input() src: any;
  returnUrl: any;
  auth2: any;
  socialUser!: SocialUser;
  private clientId = environment.clientId;

  constructor(public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private userService: UserService,
    private socialAuthService: SocialAuthService,
    private router: Router) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = new FormGroup({
      loginId: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      otp: new FormControl(null)
    });
    this.otpForm = new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.minLength(6)])
    });

     this.socialAuthService.authState.subscribe((user) => {
       this.socialUser = user;
       console.log(this.socialUser);
     });

    // @ts-ignore
    //window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById("buttonDiv"),
        { theme: "outline", size: "large", width: "100%" } 
      );
      // @ts-ignore
      //google.accounts.id.prompt((notification: PromptMomentNotification) => {}); //
    //};
  }
  
  async handleCredentialResponse(response: CredentialResponse) {
    // debugger;
    // this.alertify.success("done");
    console.log(response);
    var user: Login = {
        LoginId:"",
        Password:response.credential,
        IdentityProviderCode:"G"
    }
    this.authService.authUser(user).subscribe(result => {
      this.spinner.hide();
      if (result.isSuccess) {
        if (result.model.modifiedById == null && result.model.parentUserId != null) {
          this.router.navigate(['/change-password']);
          this.alertify.warning('Please change your password.');
        } else if (result.model.recordStatus == 'I') {
          this.router.navigate(['verification']);
          this.alertify.warning('Please verify your email address.');
        }
        else if (!this.CheckPendingProfile(result.model)) {
          this.router.navigate(['/profile']);
          this.alertify.warning('Please complete profile first.');
        } else if (this.returnUrl && this.returnUrl != '/') {
          this.router.navigateByUrl(this.returnUrl);
        }
        else {
          this.router.navigate(['/dashboard']);
        }
        this.alertify.success('Login successful.');
        this.modalService.dismissAll();
      }
      else if (!result.isSuccess) {
        this.alertify.error(result.message);
      }
    });
}

  onOtpSubmit(){
    let otp = this.otpForm.get('otp')?.value;
    if (otp == null || otp == "") {
      this.alertify.error("Please provide One-Time Password.");
      return;
    }
    this.loginForm.patchValue({
      otp: this.otpForm.get('otp')?.value
    });
    this.LoginApiCall();
  }
  onLogin() {
    this.spinner.show();
    this.userSubmitted = true;
    if (this.loginForm.valid) {
      let is2FAPassed = localStorage.getItem('Is2FAPassed');
      if (is2FAPassed == null || is2FAPassed != "1") {
        this.authService.isOTPRequired(this.loginForm.value).subscribe(result => {
          if (result.model != null && result.model == false && result.isSuccess == true) {
            this.LoginApiCall();
          }
          else if (result.isSuccess == false) {
            this.alertify.error(result.message);
          }
          else {
            this.showOtpForm = true;
          }
          this.spinner.hide();
        });
      }
      else {
        this.LoginApiCall();
      }
    }
  }
  onResend() {
    this.spinner.show();
    this.authService.isOTPRequired(this.loginForm.value).subscribe(result => {
      if (result.isSuccess == false) {
        this.alertify.error(result.message);
      }
      else {
        this.alertify.success("Verification code has been resent to your login email.");
      }
      this.spinner.hide();
    });
  }

  private LoginApiCall() {
    this.authService.authUser(this.loginForm.value).subscribe(result => {
      this.spinner.hide();
      if (result.isSuccess) {
        if (result.model.modifiedById == null && result.model.parentUserId != null) {
          this.router.navigate(['/change-password']);
          this.alertify.warning('Please change your password.');
        } else if (result.model.recordStatus == 'I') {
          this.router.navigate(['verification']);
          this.alertify.warning('Please verify your email address.');
        }
        else if (!this.CheckPendingProfile(result.model)) {
          this.router.navigate(['/profile']);
          this.alertify.warning('Please complete profile first.');
        } else if (this.returnUrl && this.returnUrl != '/') {
          this.router.navigateByUrl(this.returnUrl);
        }
        else {
          this.router.navigate(['/dashboard']);
        }
        this.alertify.success('Login Successful.');
        this.modalService.dismissAll();
      }
      else if (!result.isSuccess) {
        this.alertify.error(result.message);
      }
    });
  }

   loginWithGoogle(): void {
     this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(() => this.router.navigate(['mainpage']));;
   }

  signInWithFB(): void {
    let user: User = {
      FirstName: '',
      LastName: '',
      LoginId: '',
      Password: '',
      RoleName: '',
      ProfileType: '',
      IdentityProviderCode: ''
    };
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialusers => {
      console.log(socialusers);
      user.FirstName = socialusers.firstName;
      user.LastName = socialusers.lastName;
      user.LoginId = socialusers.email;
      user.ParentUserId = 0;
      user.RoleName = '';
      user.ProfileType = 'Admin';
      user.Password = socialusers.authToken;
      user.IdentityProviderCode = 'FB';
      this.addSocialUserToDB(user);
    });
  }

  addSocialUserToDB(user: User) {
    let fblogin: FBLogin = {
      LoginId: '',
      Password: '',
      IdentityProviderCode: ''
    }
    this.userService.addUser(user).subscribe(result => {
      console.log(result);
      if (result.isSuccess) {
        this.alertify.success(result.message);
        this.modalService.dismissAll();
        // this.router.navigate(['verification']);
        //  this.loginForm.patchValue({
        //   loginId: user.LoginId,
        //   password: user.Password
        // })
          fblogin.IdentityProviderCode = 'FB',
          fblogin.LoginId = user.LoginId,
          fblogin.Password = user.Password
          this.onFBLogin(fblogin);
      } else {
        // this.loginForm.patchValue({
        //   loginId: user.LoginId,
        //   password: user.Password
        // })
          fblogin.IdentityProviderCode = 'FB',
          fblogin.LoginId = user.LoginId,
          fblogin.Password = user.Password
          this.onFBLogin(fblogin);
      }
    });
  }

  onFBLogin(login: any) {
    this.authService.authUser(login).subscribe(result => {
      this.spinner.hide();
      if (result.isSuccess) {
        if (result.model.modifiedById == null && result.model.parentUserId != null) {
          this.router.navigate(['/change-password']);
          this.alertify.warning('Please change the password.');
        } else if (result.model.recordStatus == 'I') {
          this.router.navigate(['verification']);
          this.alertify.warning('Please verify your email address.');
        }
        else if (!this.CheckPendingProfile(result.model)) {
          this.router.navigate(['/profile']);
          this.alertify.warning('Please complete profile first.');
        } else if (this.returnUrl && this.returnUrl != '/') {
          this.router.navigateByUrl(this.returnUrl);
        }
        else {
          this.router.navigate(['/dashboard']);
        }
        this.alertify.success('Login Successful.');
        this.modalService.dismissAll();
      }
      else if (!result.isSuccess) {
        this.alertify.error(result.message);
      }
    });
  }
  CheckPendingProfile(result: any) {
    if (result.profileType == "E") {
      if (result.parentUserId == null) {
        //following routes modified by atul on 13 may 22 for making stripe mandatory
        // if (result.practiceAreas?.length == 0) {
        //   return false;
        // } else {
        return true;
        //}
      } else {
        //Following 5 lines modified by atul on 11 apr 22 for fix bug 445:Subuser is not asked to complete profile (Mobile no which is mandatory field) on first login or after that
        //result.practiceAreas?.length == 0 || 
        if (result.mobileNo == "" || result.mobileNo == null || result.mobileNo == undefined) {
          return false;
        } else {
          return true;
        }
      }
    } else if (result.profileType == "A" || result.profileType == "EA") {
      if (result.parentUserId == null) {
        if (result.barDetails?.length == 0 || result.practiceAreas?.length == 0 || result.profileDetails?.length == 0) {
          return false;
        } else {
          return true;
        }
      } else {
        //Following 5 lines modified by atul on 11 apr 22 for fix bug 445:Subuser is not asked to complete profile (Mobile no which is mandatory field) on first login or after that
        if (result.mobileNo == "" || result.mobileNo == null || result.mobileNo == undefined) {
          return false;
        } else {
          return true;
        }
      }
    } else if (result.profileType == "" && result.parentUserId == null) {
      return false;
    } else {
      return true;
    }

  }

  // ------------------------------------
  // Getter methods for all form controls
  // ------------------------------------
  get loginId() {
    return this.loginForm.get('loginId') as FormControl;
  }
  get password() {
    return this.loginForm.get('password') as FormControl;
  }
  // -----------------------


  openRegisterPopup(link: any) {
    //const modalRef = this.modalService.open(UserLoginComponent,{size:'sm', backdrop: 'static' });
    const modalRef = this.modalService.open(UserRegistrationComponent);
    modalRef.componentInstance.src = link;
  }
  openForgotPasswordPopup(link: any) {
    //const modalRef = this.modalService.open(UserLoginComponent,{size:'sm', backdrop: 'static' });
    const modalRef = this.modalService.open(ForgotPasswordComponent);
    modalRef.componentInstance.src = link;
  }

}

