import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from './alertify.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGaurdForVerifiedAccountService implements CanActivate {

  constructor(private authService: AuthService, public alertify: AlertifyService, public modalService: NgbModal, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //Code modified by atul on 14 may 22 to fix bug in route graurd reported by nirav "user can open pages like dashboard, profile without email verification
    let cu = this.authService.currentUserValue;

    if (this.authService.isLoggedIn() && (cu.recordStatus == 'I' || cu.recordStatus == null) && cu.recordStatus != undefined) {
      this.alertify.warning('Please verify your email address.');
      this.router.navigate(['verification']);
      return false;
    } else if (this.authService.isLoggedIn() && this.checkStripeNotConnected()) {
      this.alertify.warning('Please complete your profile first.');
      this.router.navigate(['profile']);
      return false;
    }
    return true;
  }

  checkStripeNotConnected() {
    let cu = this.authService.currentUserValue;
    // if (cu.parentUserId == null && (cu.profileType == "A" || cu.profileType == "EA") && (cu.isStripeConnected == null || cu.isStripeConnected == false)) {
    //   return true;
    // }
    if (cu.parentUserId == null && ((cu.profileType == "I" || cu.profileType == "EA" || cu.profileType == "E") && (cu.isPaymentSetupCompleted == null || cu.isPaymentSetupCompleted == false))) {
      return true;
    }
    if (cu.parentUserId != null && (cu.mobileNo == undefined || cu.mobileNo == "")) {
      return true;
    }
    return false;
  }
}
