import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppearanceService } from 'src/app/services/appearance.service';
import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-appearance-view',
  templateUrl: './appearance-view.component.html',
  styleUrls: ['./appearance-view.component.css']
})
export class AppearanceViewComponent implements OnInit {

  @Input() Appearance: any;
  constructor(public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private authService: AuthService,
    private appearanceService: AppearanceService) { }
    public currentUser: Number;

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue.id || 0;
    console.log("user,",this.authService.currentUserValue);
  }

  DownloadDocs(docname: string, name: string) {
    this.appearanceService.DownloadDocs(docname)
      .subscribe(response => {
        saveAs(response, name)
      });
  }
}
