<!-- ======= Breadcrumbs ======= -->
<section class="breadcrumbs mb-3">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h1>About Us</h1>
      <ol>
        <li><a href="index.html">Home</a></li>
        <li>About Us</li>
      </ol>
    </div>
  </div>
</section>
<!-- End Breadcrumbs -->
<!-- ======= Page Content ======= -->
<section>
  <div class="container mb-3">
    <div class="row">
      <h2>What We Are All About</h2>
      <p>We exist to become the most utilized, most trusted and most efficient web-based appearance facilitator around.  We achieve this by empowering you, either the hiring attorney or the job seeker, with complete visibility around the hiring process. </p>
      <p>Our founder, a practicing attorney herself, believes in making the process of contracting with an attorney on an engagement-by-engagement basis as simple as possible.</p>
      <p>That's why at Videtur, we are all about transparency in allowing you, the practitioner, to identify the best candidates and opportunities so that you can quickly get back to serving your clients' needs.  There are no "black boxes" here.  We provide the means for connecting, and you and your team identify the best fit.</p>
    </div>
  </div>
</section>
<!-- End Page Content -->
