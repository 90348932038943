
          <div class="card card-primary">
            <div class="card-header">
              <h3 style="display: inline ;">Transaction details  
                </h3>
                
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn"
                  (click)="closeButtonClick()">
                  <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                      <div  class="card border-success border-top border-bottom">
                        <div class="card-header">
                          <h5><b> {{paymentStatusMessage}}</b></h5> <span *ngIf="!isError">Below are the details of your last transaction: </span>
                        </div>
                        <div  *ngIf="!isError" class="card-body">
                          <div id="divForPrint"  style="width: 500px;">
                            <table> <!-- style="border:1px" border="1" style="width: 500px;background-color: aliceblue;">
                              <tr style="background-color: gray;">
                                <td > <img src="assets/img/Videtur_Logo.png" alt="Videtur" /></td>
                                <td style="text-align: right;"> <label class="col-sm-12 h2 " style="padding-top: 15px; padding-right:5px;">Payment Receipt</label></td>
                              </tr>
                              <tr><td><br></td></tr> -->
                              <tr>
                                <td width="40%"> <label class="col-sm-12">Transaction Ref No. </label></td>
                                <td> <label>: {{ReferenceNo}}</label></td>
                              </tr>
                              <tr>
                                <td> <label class="col-sm-12">Transaction Amount </label></td>
                                <td>
                                  <label *ngIf="profileType != 'A'" >: {{TransactionAmount | currency:'USD':'symbol'}} </label>
                                  <label *ngIf="profileType == 'A'">: {{TransactionAmountForAtt | currency:'USD':'symbol'}} </label>
                                </td>
                              </tr>
                              <tr *ngIf="profileType != 'A'" >
                                <td> <label class="col-sm-12">Transaction Description </label></td>
                                <td>
                                  <label>:&nbsp;</label>
                                   <label *ngIf="LateCharges > 0.0" >Late Charges: {{LateCharges | currency:'USD':'symbol'}}, &nbsp; </label>
                                  <label> <span *ngIf="LateCharges > 0.0" > &nbsp; </span> Videtur Charges: {{VidtureCharges | currency:'USD':'symbol'}} </label>
                                </td>
                              </tr>
                              <tr *ngIf="profileType == 'A' && LateCharges > 0.0" >
                                <td> <label class="col-sm-12">Transaction Description </label></td>
                                <td>
                                  <label>:&nbsp;</label>
                                   <label *ngIf="LateCharges > 0.0" >Late Charges: {{LateCharges | currency:'USD':'symbol'}} </label>
                                </td>
                              </tr>
                              <tr>
                                <td><label class="col-sm-12">Payment Status </label></td>
                                <td><label>: {{PaymentStatus}}</label></td>
                              </tr>
                              <!-- <tr>
                                <td><label class="col-sm-12">Receipt No. </label></td>
                                <td> <label>: {{ReceiptNo}}</label></td>
                              </tr> -->
                              <tr>
                                <td> <label class="col-sm-12">Receipt Date </label></td>
                                <td> <label>: {{ReceiptDate}}</label></td>
                              </tr>
                              <tr>
                                <td> <label class="col-sm-12">Invoice Number </label></td>
                                <td> <label>: {{InvoiceNumber}}</label></td>
                              </tr>
                              <!-- <tr><td><br></td></tr>
                              <tr  style="background-color: gray;">
                                <td colspan="2" style="text-align:center"> <label class="col-sm-12 h2 ">Videtur LLC</label></td>
                              </tr>
                              <tr style="background-color: gray;">
                                <td colspan="2" style="text-align:center"> <label class="col-sm-12 h5 ">75 Broadway Ste 202, San Francisco, CA 94111</label></td>
                              </tr> -->
                            </table>
                          </div>
                          <br>
                          <br>
                          <!-- <div class="form-group">
                            <label class="col-sm-5"> </label>
                            <button class="col-sm-2 btn btn-success" [useExistingCss]="true" printSectionId="divForPrint"
                              ngxPrint>Print Receipt</button>

                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
