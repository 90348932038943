import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  @Input() fromSignUp? : boolean = false;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
