    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs mb-3">
      <div class="container">
          <div class="d-flex justify-content-between align-items-center">
              <h1>Change Password</h1>
              <ol>
                  <li><a routerLink="/dashboard">Home</a></li>
                  <li><a routerLink="/settings">Settings</a></li>
                  <li>Change password</li>
              </ol>
          </div>
      </div>
  </section><!-- End Breadcrumbs -->
  <section>
    <div class="container mb-3">
      <div class="row">
      <div class="col-md-6 m-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h3>Change Password</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <div *ngIf="!isforgetPassword" class="form-group">
              <label for="inputAddress">Current Password</label>
                  <input type="password" formControlName="currentPassword" class="form-control" id="inputAddress" placeholder="Current password" required>
                  <span *ngIf="!currentPassword.valid && (currentPassword.touched || userSubmitted)" class="error-block">
                    <span *ngIf="currentPassword.errors?.required">
                      Please provide password.
                    </span>
                    <span *ngIf="currentPassword.errors?.minlength">
                      Password should not be lesser than 8 character.
                    </span>
                  </span>
            </div>
            <div class="form-group">
              <label for="inputAddress">New Password</label>
                  <input type="password" formControlName="newPassword" class="form-control" id="inputAddress" placeholder="New password" required>
                  <span *ngIf="!newPassword.valid && (newPassword.touched || userSubmitted)" class="error-block">
                    <span *ngIf="newPassword.errors?.required">
                      Please provide password.
                    </span>
                    <span *ngIf="newPassword.errors?.minlength">
                      Password should not be lesser than 8 character.
                    </span>
                  </span>
            </div>
            <div class="form-group">
              <label for="inputAddress">Confirm Password</label>
                  <input type="password" formControlName="newConfirmPassword" class="form-control" id="inputAddress" placeholder="Confirm password" required>
                  <span *ngIf="!newConfirmPassword.valid && (newConfirmPassword.touched || userSubmitted)" class="error-block">
                    <span *ngIf="newConfirmPassword.errors?.required">
                      Please provide password.
                    </span>
                    <span *ngIf="newConfirmPassword.errors?.minlength">
                      Password should not be lesser than 8 character.
                    </span>
                    <span *ngIf="newConfirmPassword.errors?.passwordMismatch && newConfirmPassword.touched" class="error-block">
                      Password not matched.
                    </span>
                  </span>
            </div>
            <button type="submit" class="btn btn-primary">Update</button>
          </form>
        </div>
      </div>
    </div>
    </div>
    </div>
      </section>
