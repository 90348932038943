import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AfterViewInit,  ElementRef} from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertifyService } from 'src/app/services/alertify.service';
import { NexioPaymentProcessedDTO, UserPaymentSetupDetailDTO } from 'src/app/models/nexio';
import { PaymentReceiptComponent } from '../payment-receipt/payment-receipt.component';


@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.css']
})
export class PayInvoiceComponent implements OnInit {
  @Input() invoiceId: any;
  @Input() token:any;
  @Input() totalForPay:any;
  
  constructor(private modalService: NgbModal,private route: ActivatedRoute, private userService: UserService, private spinner: NgxSpinnerService, public authService: AuthService,public activeModal: NgbActiveModal, private elementRef:ElementRef, private alertify: AlertifyService) { }
  private paymentGatewayUrl = environment.paymentGatewayUrl;

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (event.origin === this.paymentGatewayUrl) {
      const eventData = event.data?.data;
      const eventType = event.data?.event;

      if (eventType === 'loaded') {
        this.spinner.hide();    
      }
      if (eventType === 'error') {
        this.alertify.error("Payment gateway error: " +event.data.data.message);
      }
      if (eventType === 'processed') {
        var userMasterId: Number = this.authService.currentUserValue.id || 0;
        const dto: NexioPaymentProcessedDTO  = {
          UserMasterId: userMasterId,
          InvoiceId:this.invoiceId,
          ResponseJson:JSON.stringify(eventData),
          Status:'processed',
          PaymentMode: 'card'
        }
        
        this.userService.SaveNexioPaymentRes(dto).subscribe(result => {
          this.spinner.hide();    
          if (result.model != null) {
            this.activeModal.dismiss('Cross click');
            const modalRef = this.modalService.open(PaymentReceiptComponent, { size: 'lg', backdrop: 'static' }); 
              //modalRef.componentInstance.src = invoice;
            modalRef.componentInstance.invoiceId = this.invoiceId;
          }
          else {
            this.alertify.error("An unexpected error occurred. Please try again.");
          }
          //this.spinner.hide();
        });
          // var userMasterId: Number = this.authService.currentUserValue.id || 0;
          // const dto: NexioPaymentProcessedDTO  = {
          //   UserMasterId: userMasterId,
          //   InvoiceId:this.invoiceId,
          //   ResponseJson:eventData,
          //   Status:eventType
          // }
          
          // this.userService.SaveNexioPaymentRes(dto).subscribe(result => {
          //   if (result.model != null) {
          //     const modalRef = this.modalService.open(PaymentReceiptComponent, { size: 'lg', backdrop: 'static' }); 
          //     //modalRef.componentInstance.src = invoice;
          //     modalRef.componentInstance.invoiceId = this.invoiceId;
          //   }
          //   else {
          //     this.alertify.error("Something went wrong.");
          //   }
          //   //this.spinner.hide();
          // });
      }
    }
  }

  ngAfterViewInit() {
    var iframeBaseUrl =this.paymentGatewayUrl + "/pay/v3"; 
    var oneTimeUseToken = "?token=" + this.token;
    var returnHtml = "&shouldReturnHtml=true";
    var fullurl = iframeBaseUrl + oneTimeUseToken + returnHtml;
    
    this.elementRef.nativeElement.querySelector('#myIframe').src = fullurl;
    this.elementRef.nativeElement.querySelector('#myForm').addEventListener('submit', (event:any) => {
          event.preventDefault();
          this.elementRef.nativeElement.querySelector('#myIframe').contentWindow.postMessage('posted', this.paymentGatewayUrl);
          this.spinner.show();
          return false; 
      });	
  }
  
  onClick(event: any) {
    console.log(event);
  }
  
  ngOnInit() {
    this.spinner.show();
    // this.profileType = this.authService.currentUserValue.profileType || "";

    // this.route.queryParams.subscribe(params => {
    //   console.log(this.invoiceId);
    // }); 
  }
  
}
