  <!-- Sidebar -->
  <div class="bg-light border-right border-top pt-1" id="sidebar-wrapper">
    <div class="sidebar-heading"><i class="fa fa-filter"></i> Filters </div>
    <div class="border-bottom"></div>
    <div class="list-group list-group-flush p-2 ps-3">
        <!-- BEGIN FILTER BY CASE TYPE -->        
        <div class="form-group">
            <h6>By {{caption}}:</h6>            
            <div class="slider-primary mb-2">
                <ng-select [placeholder]="placeHolder" [multiple]="true" [(ngModel)]="selectedCaseType" style="width: 100%;"
                    (change)="SearchFilter(selectedCaseType);">
                    <ng-option *ngFor="let casetype of casetypes" [value]="casetype.autoId">
                        {{casetype.practiceArea}}
                    </ng-option>
                    
                </ng-select>
            </div>
        </div>
        <!-- BEGIN FILTER BY LOCATION -->
        <h6>By Location:</h6>
        <div class="form-group">
            <div class="input-group">
                <ng-select [placeholder]="'Select State'" [multiple]="true" (change)="SearchFilter($event)"
                    [(ngModel)]="selectedState" style="width: 100%;">
                    <ng-option *ngFor="let state of states" [value]="state.autoId">
                        {{state.stateName}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <ng-select [placeholder]="'Select County'" [multiple]="true" [(ngModel)]="selectedCounty"
                    (change)="SearchFilter(selectedCounty)" style="width: 100%;">
                    <ng-option *ngFor="let county of counties" [value]="county.autoId"
                        [disabled]="county.disabled">{{county.countyName}}</ng-option>
                </ng-select>
            </div>
        </div>

        <div class="padding"></div>

        <!-- BEGIN FILTER BY DATE -->
        <h6>By Date:</h6> 
         <div class="form-group">
            Date From
            <div class="input-group date form_date" data-date="2014-06-14T05:25:07Z"
                data-date-format="mm-dd-yyyy" data-link-field="dtp_input1">
                <input type="date" [(ngModel)]="DateFrom" placeholder="mm-dd-yyyy"
                    (change)="onDateFromChange('appearanceDateTime')" class="form-control">
            </div>

            Date To
            <div class="input-group date form_date" data-date="2022-01-14T05:25:07Z"
                data-date-format="mm-dd-yyyy" data-link-field="dtp_input2">
                <input type="date" [(ngModel)]="DateTo" placeholder="mm-dd-yyyy"
                    (change)="onDateFromChange('appearanceDateTime')" class="form-control">
            </div>
        </div>
        <!-- END FILTER BY DATE -->

        <div class="padding"></div>

        <!-- BEGIN FILTER BY PRICE -->
        <!-- <div *ngIf="CalledFor!='Appearance' && statusApplication =='Applied'" class="form-group">
            <h6>By hourly rate ($):</h6>
            <div class="custom-ngx-slider">
                <ngx-slider [(value)]="value" (userChangeStart)="onUserChangeStart($event)"
                    (userChangeEnd)="onUserChangeEnd($event)" (userChange)="onUserChange($event)"
                    [(highValue)]="highValue" [options]="options"></ngx-slider>
            </div>
        </div> -->

         <!-- BEGIN FILTER BY LANGUAGE -->
         <!-- <div *ngIf="CalledFor=='Application' || CalledFor=='Attorney'" class="form-group">
            <h6>Languages:</h6>
            <ng-select [placeholder]="'Select Languages'" (change)="SearchFilter(selectedLanguage);" [multiple]="true" [(ngModel)]="selectedLanguage" style="width: 100%;">
              <ng-option *ngFor="let language of Languages" [value]="language.recordName">
                {{language.recordName}}
              </ng-option>
            </ng-select>
          </div> -->
          <!-- END FILTER BY LANGUAGE -->
          <!-- BEGIN FILTER BY RATING -->
          <!-- <div *ngIf="CalledFor=='Application' || CalledFor=='Attorney'" class="form-group">
            <h6>Rating:</h6>
            <ng-select [placeholder]="'Select Rating'" [multiple]="true" [(ngModel)]="selectedRating" style="width: 100%;">
              <ng-option *ngFor="let rating of Ratings" [value]="rating.id">{{rating.name}}</ng-option>
            </ng-select>
          </div> -->
          <!-- END FILTER BY RATING -->
        <!-- END FILTER BY PRICE -->
        <!-- BEGIN FILTER BY APPEARANCE TYPE -->
        <!-- <div *ngIf="FieldFor=='A' || (FieldFor =='E' && (CalledFor=='Apperance' || CalledFor=='Application'))" class="form-group">
            <h6>By Type:</h6>
            Case Type
            <div class="slider-primary mb-2">
                <ng-select [placeholder]="'Select Case Type'" [multiple]="true" [(ngModel)]="selectedCaseType" style="width: 100%;"
                    (change)="SearchFilter(selectedCaseType);">
                    <ng-option *ngFor="let casetype of casetypes" [value]="casetype.autoId">
                        {{casetype.practiceArea}}
                    </ng-option>
                    
                </ng-select>
            </div>
            Apperance Type
            <div class="slider-primary">
                <ng-select [placeholder]="'Select Appearance Type'" [multiple]="true" [(ngModel)]="selectedAppearanceType"
                    (change)="SearchFilter(selectedAppearanceType)" style="width: 100%;">
                    <ng-option *ngFor="let appearancetye of appearancetypes" [value]="appearancetye.autoId">
                        {{appearancetye.appearanceType1}}
                    </ng-option>
                    
                </ng-select>
            </div>
        </div> -->
        <!-- END FILTER BY APPEARANCE TYPE -->
        <!-- BEGIN FILTER BY STATUS -->
        <!-- <h6 *ngIf="CalledFor=='Appearance'">By Status:</h6>
        <ng-select *ngIf="CalledFor=='Appearance'" [placeholder]="'Select Status'" [multiple]="true" [(ngModel)]="selectedStatus"
            (change)="SearchFilter(selectedStatus)" style="width: 100%;">
            <ng-option *ngFor="let status of statuses" [value]="status.status">{{status.status}}</ng-option>
        </ng-select> -->
        <!-- END FILTER BY STATUS -->
    </div>
</div>