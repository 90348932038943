export const environment = {
  production: true,
  //apiUrl: 'https://localhost:5003/api/',//'http://localhost:21897/api/',
  apiUrl: 'https://videturproapi.azurewebsites.net/api/', // change this URL as production API url,
  //apiUrl: 'https://videturprodevapi.azurewebsites.net/api/', // dev
  //apiUrl: 'https://videturprostagingapi.azurewebsites.net/api/', // stage
  feature: {
    Idle: {
      enable: true,
      idleTimeSeconds: 1200, // In future we will pick this from DB which will set from Admin module
      timeOutSeconds: 1
    }
  },
  
  //For local: using atulyadav012@gmail.com
  //clientId:"36391891638-mpnbjna6gftikakja3v701v6mqbhg425.apps.googleusercontent.com"
  //For Dev using atul.yadav@merriment.co
  //clientId:"86628243265-56560uk7k7smctoi2lb7vahcd4965jue.apps.googleusercontent.com",
  //For prod google login using info@merriment.co
  clientId:"547670947701-jduodc73csvl9lnkhj3f6qjic9giub5f.apps.googleusercontent.com", //client secret: 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
  
  //for dev
  //paymentGatewayUrl:"https://api.nexiopaysandbox.com",
  //for prod
  paymentGatewayUrl:"https://api.nexiopay.com",
  //for dev i payout
  //iPayoutMemberLoginUrl:"https://videtur.testewallet.com/MemberLogin.aspx"
  //for prod i payout
  iPayoutMemberLoginUrl:"https://videtur.globalewallet.com/MemberLogin.aspx"
};
