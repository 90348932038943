import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NexioPaymentBySavedMethodDTO, NexioPaymentProcessedDTO } from 'src/app/models/nexio';
import { IsStripeConnectedDTO } from 'src/app/models/stripe';
import { AlertifyService } from 'src/app/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UserService } from 'src/app/services/user.service';
import { PayInvoiceComponent } from '../pay-invoice/pay-invoice.component';
import { PaymentReceiptComponent } from '../payment-receipt/payment-receipt.component';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.css']
})
export class SelectPaymentMethodComponent implements OnInit {

  @Input() src: any;

  public selectedValue: number;
  public paymentMethods: any;
  public CurrentPayBrand:string;
  public CurrentPayLastDigits:string;

  constructor(public activeModal: NgbActiveModal,
    public invoiceService: InvoiceService,
    private userService: UserService,
    private alertify: AlertifyService, private modalService: NgbModal, private authService: AuthService, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.spinner.show();
    this.selectedValue = 0;
    this.src.latePaymentPct = this.src.latePayment * 100 / this.src.totalAmount;  
    this.src.totalForPay = Math.round((this.src.totalAmount +this.src.latePayment+this.src.videturCommAmount)* 100 ) / 100;  
    this.getPaymentMethods();
    this.getSavedPaymentMethods();
    //this.handleChangeMethod(0,0);

  }
  getPaymentMethods() {
    this.userService.getPaymentMethods().subscribe(result => {
      this.paymentMethods = result.list;
    });
  }
  
  getSavedPaymentMethods() {
    var userMasterId: Number = this.authService.currentUserValue.id || 0;
    var profileType = this.authService.currentUserValue.profileType || "";
    const isStripeConnectedDTO: IsStripeConnectedDTO = {
      UserMasterId: userMasterId,
      ProfileType: profileType
    }
    this.userService.GetIsPaymentSetupCompleted(isStripeConnectedDTO).subscribe(result => {
      this.spinner.hide();
      if (result.model != null) {
        // this.IsPaymentSetupCompleted = true;
        // this.stripeForm.patchValue({
        //   hidIsPaymentSetupCompleted: true
        // });
        //this.CurrentPayMethod = result.model.methodType;
        this.CurrentPayBrand = result.model.cardType;
        this.CurrentPayLastDigits = result.model.cardLastDigits;
        
        // this.CurrentPayAcType = result.model.acType;
        // this.CurrentPayBank = result.model.bankName;
      }
      // else {
      //   this.IsPaymentSetupCompleted = false;
      //   this.stripeForm.patchValue({
      //     hidIsPaymentSetupCompleted: false
      //   });
      // }
    });
  }
  payInvoice(invoice: any){
    if(this.selectedValue == 0)
    {
      this.alertify.error("Please select a payment method.");
      return;
    }
    else if(this.selectedValue == 99){
      this.spinner.show();    
      var userMasterId: Number = this.authService.currentUserValue.id || 0;
      const dto: NexioPaymentBySavedMethodDTO  = {
        UserMasterId: userMasterId,
        InvoiceId:invoice.autoId,
        SavedMethodId:this.selectedValue
      }
      
      this.userService.NexioPaymentBySavedMethod(dto).subscribe(result => {
        this.spinner.hide();    
        if (result.model != null) {
          this.activeModal.dismiss('Cross click');
          const modalRef = this.modalService.open(PaymentReceiptComponent, { size: 'lg', backdrop: 'static' }); 
          modalRef.componentInstance.invoiceId = invoice.autoId;
        }
        else {
          this.alertify.error("An unexpected error occurred. Please try again.");
        }
      });
      return;
    
    }
    else{
       this.invoiceService.GetPaymentToken(invoice.autoId).subscribe(result => {
        if(result.model && result.model != '')  
        {
          invoice.PaymentMethodId = this.selectedValue;
          this.activeModal.dismiss('Cross click');
          const modalRef = this.modalService.open(PayInvoiceComponent, { size: 'md', backdrop: 'static' }); 
          modalRef.componentInstance.invoiceId = invoice.autoId;
          modalRef.componentInstance.token = result.model;
          modalRef.componentInstance.totalForPay = invoice.totalForPay;
        }
      });
    }
    return;
    this.invoiceService.PayInvoice(invoice).subscribe(result => {
      if(result.isSuccess)
      {
        window.location.href = result.model.accountLinkUrl; 
      }
      else  
      {
        this.alertify.error(result.message);
      }
    });
  }
}
