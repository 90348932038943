<div class="card card-info">
    <div class="card-header">
        <h5>Rate {{headerText}}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn"
            (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="card-body">
        <ngb-rating [max]="5" [(rate)]="rating" [readonly]="false"></ngb-rating> <br />

        <div class="form-group">
            <label>Message</label>
            <textarea class="form-control" [(ngModel)]="Message" row="3"></textarea>
          </div>
        <div class="card-footer text-center">
            <button type="submit" (click)="Submit()" class="btn btn-info">Submit</button>
        </div>
    </div>
</div>

<!-- <p>Rate: <strong>{{rating}}</strong></p> -->