import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountToWords'
})
export class AmountToWordsPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) {
      return 'Invalid amount';
    }

    const units = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const tens = [
      '', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
    const scales = [
      '', 'Thousand', 'Million', 'Billion', 'Trillion'
    ];

    let amount = Math.abs(value);
    let decimal = Math.round((amount - Math.floor(amount)) * 100);
    let numWords = '';

    if (amount === 0) {
      numWords = units[0];
    } else {
      let scaleCounter = 0;
      while (amount > 0) {
        let threeDigits = amount % 1000;
        if (threeDigits !== 0) {
          let digitWords = '';

          if (threeDigits >= 100) {
            digitWords += units[Math.floor(threeDigits / 100)] + ' Hundred ';
            threeDigits %= 100;
          }

          if (threeDigits >= 10 && threeDigits <= 19) {
            digitWords += units[threeDigits] + ' ';
          } else {
            digitWords += tens[Math.floor(threeDigits / 10)] + ' ';
            threeDigits %= 10;

            if (threeDigits > 0) {
              digitWords += units[threeDigits] + ' ';
            }
          }

          numWords = digitWords + scales[scaleCounter] + ' ' + numWords;
        }

        scaleCounter++;
        amount = Math.floor(amount / 1000);
      }
    }

    let decimalWords = '';
    if (decimal > 0) {
      if (decimal >= 10 && decimal <= 19) {
        decimalWords = units[decimal] + ' ';
      } else {
        decimalWords = tens[Math.floor(decimal / 10)] + ' ';
        decimal %= 10;

        if (decimal > 0) {
          decimalWords += units[decimal] + ' ';
        }
      }

      numWords += (value < 0 ? 'Minus ' : '') + 'and ' + decimalWords + 'Cents';
    } else if (value === 0) {
      numWords = 'Zero';
    }

    return (value < 0 ? 'Minus ' : '') + "dollar " + numWords.trim().replace("undefined ", "") + " only";
    }
}

//     return (value < 0 ? 'Minus ' : '') + "dollar " + numWords.trim()+ " only";
//   }
// }

//     return "dollar " + numWords.trim()+ " only";
//   }
// }

//       return "dollar " + result.trim() + " only";
//     }
//   }
// }
