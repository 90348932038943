<!-- ======= Breadcrumbs ======= -->
<section class="breadcrumbs mb-3">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Contact Us</h1>
      <ol>
        <li><a href="index.html">Home</a></li>
        <li>Contact Us</li>
      </ol>
    </div>
  </div>
</section>
<!-- End Breadcrumbs -->
<!-- ======= Page Content ======= -->
<section>
  <div class="container mb-3">
    <div class="row">
      <div class="col-sm-6">
        <div class="card border-success border-top border-bottom">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2">
                <button type="button" class="btn btn-outline-success btn-round ps-lg-3 pe-lg-3">
                  <i class="fa fa-map-marker"></i>
                </button>
              </div>
              <div class="col-sm-10">
                <h5 class="card-title">Location:</h5>
                <p class="card-text">Videtur LLC, 95 Third Street, 2nd Floor, San Francisco, CA 94103</p>
              </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
              <div class="col-sm-2">
                <button type="button" class="btn btn-outline-success btn-round">
                  <i class="fa fa-envelope-o"></i>
                </button>
              </div>
              <div class="col-sm-10">
                <h5 class="card-title">Email:</h5>
                <p class="card-text"><a href="mailto:info@videturpro.com">info@videturpro.com</a></p>
              </div>
            </div>
            <!--<div class="row">&nbsp;</div>
            <div class="row">
              <div class="col-sm-2">
                <button type="button" class="btn btn-outline-success btn-round ps-lg-3 pe-lg-3">
                  <i class="fa fa-mobile-phone"></i>
                </button>
              </div>
              <div class="col-sm-10">
                <h5 class="card-title">Call:</h5>
                <p class="card-text"><a href="#">+1 5589 55488 55</a></p>
              </div>
            </div>-->
            <div class="row">&nbsp;</div>
            <div class="row">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509916.957423575!2d-123.79759793610344!3d37.18430344877116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1625838741391!5m2!1sen!2sin" width="100%" height="427" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card border-success border-top border-bottom">
          <div class="card-body">
            <form *ngIf="submitSuccess==false" [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label class="col-sm-4">Your Name</label>
                <input type="text" formControlName="name" class="form-control mr-1"
                             [ngClass]="{ 'is-invalid': (submitted && f.name.errors) }" id="name"
                             placeholder="Your Name">
                      <div *ngIf="(submitted || f.name.errors)" class="invalid-feedback">
                        <div *ngIf="f.name.errors?.required">Please enter your name.</div>
                      </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-4">Email</label>
                <input type="text" formControlName="email" class="form-control mr-1"
                             [ngClass]="{ 'is-invalid': (submitted && f.email.errors) }" id="email"
                             placeholder="Email">
                      <div *ngIf="(submitted || f.email.errors)" class="invalid-feedback">
                        <div *ngIf="f.email.errors?.required">Please enter your email.</div>
                      </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4">Mobile</label>
                <input type="text" formControlName="mobileNo" class="form-control mr-1"
                             [ngClass]="{ 'is-invalid': (submitted && f.mobileNo.errors) }" id="mobileNo"
                             placeholder="Mobile No.">
                      <div *ngIf="(submitted || f.mobileNo.errors)" class="invalid-feedback">
                        <div *ngIf="f.mobileNo.errors?.required">Please enter your mobile number.</div>
                      </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4">Subject</label>
                <input type="text" formControlName="subject" class="form-control mr-1"
                             [ngClass]="{ 'is-invalid': (submitted && f.subject.errors) }" id="subject"
                             placeholder="Subject">
                      <div *ngIf="(submitted || f.subject.errors)" class="invalid-feedback">
                        <div *ngIf="f.subject.errors?.required">Please enter subject.</div>
                      </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4">Message</label>
                <textarea rows="5"  formControlName="message" class="form-control mr-1"
                             [ngClass]="{ 'is-invalid': (submitted && f.message.errors) }" id="message"
                             placeholder="Message"></textarea>
                      <div *ngIf="(submitted || f.message.errors)" class="invalid-feedback">
                        <div *ngIf="f.message.errors?.required">Please enter message.</div>
                      </div>
              </div>
              <div class="form-group">
                <ngx-recaptcha2 #captchaElem
                  [siteKey]="key"
                  (success)="handleSuccess($event)"
                  formControlName="recaptcha">
                </ngx-recaptcha2>
              </div>
              <div class="form-group">
                <input type="submit" class="btn btn-success" value="Submit" />&nbsp;
              </div>
            </form>
            <div *ngIf="submitSuccess">
              Thank you for contacting Videtur Pro. <br> Someone from our team will get back to you soon.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Page Content -->
