<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
      aria-current="true" aria-label="Slide 1" (click)="Check(true)">
      LOOKING FOR AN ATTORNEY?
    </button>
    <button type="button" data-bs-target="#carouselExampleIndicators" (click)="Check(false)" data-bs-slide-to="1" aria-label="Slide 2">
      SEEKING AN APPEARANCE OPPORTUNITY?
    </button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="carousel-caption d-md-block">
        <div class="centerMe">
          <div class="col-md-12 col-xl-8">
            <div class="text-center CenterControl">
              <h3>Search for Attorneys</h3>
            </div>
            <div class="text-center CenterControl"><app-top-filter [CalledFor]="'Attorney'" [displayat]="'H'"></app-top-filter></div>
          </div>
        </div>
      </div>
      <img src="assets/img/slider/new-slider-1.jpg" class="d-block w-100" alt="Search for Attorneys">
    </div>
    <div class="carousel-item">
      <div class="carousel-caption d-md-block">
        <!--<h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>-->
        <div class="centerMe">
          <div class="col-md-12 col-xl-8">
            <div class="text-center CenterControl">
              <h3>Search for Appearances</h3>
            </div>
            <div class="text-center CenterControl"><app-top-filter [CalledFor]="'Appearance'" [displayat]="'H'"></app-top-filter></div>
          </div>
        </div>
      </div>
      <img src="assets/img/slider/new-slider-2.jpg" class="d-block w-100" alt="Search for Appearances">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
