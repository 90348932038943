<!-- ======= Breadcrumbs ======= -->
<section class="breadcrumbs mb-3">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h1>How it works</h1>
      <ol >
        <li><a href="index.html">Home</a></li>
        <li>How it works</li>
      </ol>
      
    </div>
  </div>
</section>
<!-- End Breadcrumbs -->
<!-- ======= Page Content ======= -->
<section>
  <div class="container mb-3">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Sign up and post an appearance opportunity.
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="row">
              <div class="col-12">
                <ol>
                  <li>Open a standard web browser such as Chrome, Edge or Firefox and type Videtur URL <a href="https://www.videturpro.com">https://www.videturpro.com</a> </li>
                  <li>
                    On the Videtur home page click on 'Signup' option on the top Right corner.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/signup_2.jpg" class="w-100" /></div>
                  </li>
                  <li>On the signup window, provide name, email address and password to signup</li>
                  <li>Check and accept the Terms and Conditions.</li>
                  <li>
                    Complete the signup. You will be logged in to the application.
                    <div class="col-md-4" style="margin:auto;"><img src="assets/img/howitworks/signup-form.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    On login, you will be asked to complete your profile.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/email-verification_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    Kindly login to the email account you provided on Videtur and click on the link to verify the email.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/verification-email_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    On clicking the link in the email, your email id is verified.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/verification-complete_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    On login, you will be asked to complete your profile.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-1_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    To complete your profile-
                    <ul style="font-weight: bold;">
                      <li>Begin by selecting your role: either a Hiring Attorney or an Attorney seeking Appearances.</li>
                      <li>The required profile information will vary based on the chosen role.</li>
                      <li>You have the option to select both roles if applicable, and provide information relevant to each.</li>
                      <li>Expand each section to view and complete the details.</li>
                      <li>Certain fields are mandatory, while others are marked as 'Optional'.</li>
                    </ul>
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-2_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    Final step of the profile is setting up a payment account.
                    <p><br />If you are signing up as an Employer, you just need to set up a payment method so that Attorney fees can be auto-debited from your account on completion of appearance. </p>
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-3_2.jpg" class="w-100" /></div>
                    <p>Select Auto debit payment settings and click "Start Setup", You will be asked to enter your card details. </p>
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-4_2.jpg" class="w-100" /></div>
                    <p>Enter the credit card details and click "Add" button to complete auto debit payment settings.</p>
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-5_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    If you are signing up as an Attorney seeking Appearances, you need to set up a Nomad account to be able to accept payments for the appearances.
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-6_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    Once you click "Create Wallet", Your Nomad Wallet creation is in-progress. Nomad sent your an email Please open that email and complete you wallet creation.
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/complete-profile-7_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    Once the payment setup is completed, Your Profile is now completed.
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/Completed-Profile_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    The Employer can now post an appearance by clicking on the 'New Appearance' option on the 'Appearances' menu.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/new-appearance_1.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    On the '+ New Appearance' page, fill the required details and 'Save' to create a new appearance.
                    <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/new-appearance-1_2.jpg" class="w-100" /></div>
                  </li>
                  <li>
                    As soon as the appearance is created, it will show up on the 'Open appearance' page.
                    <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/appearances_3.jpg" class="w-100" /></div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Search through available attorneys on an ongoing real-time basis.
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>
                Employers can search for available attorneys in any of the two ways -
                <ul>
                  <li>Open search (through Top search bar)</li>
                  <li>Searching Attorneys matching Appearance criteria</li>
                </ul>

                <p class="mt-4">Open search - <br />Employers can use Open search (through Top search bar) to find Attorneys based on Practice areas and/or State. At least one search criteria (State or Practice area) must be provided, but both can be provided as well. </p>

                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/employer-search-appearance.jpg" class="w-100" /></div>

                <p class="mt-4">Searching Attorneys matching Appearance criteria - <br />Employers can search for Attorneys by using the ‘Find Attorney’ button on the Appearance card. This will show Attorneys matching the Appearance State and Case type. </p>

                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/employer-search-result.jpg" class="w-100" /></div>
              </li>
              <li>
                In both the above cases, the search results show Attorneys matching search criteria. The employer can further narrow down or broaden the search criteria using the filters on the left.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/invite-attorney.jpg" class="w-100" /></div>
              </li>
              <li>
                Search will show Attorneys matching the specified criteria. The Employer can invite one or more attorneys to apply for the appearance.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/invite-attorney-1.jpg" class="w-100" /></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Attorneys view the appearance opportunity and respond.
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>
                Attorneys can find appearance opportunities in any of the following ways -
                <ul>
                  <li>Searching for Appearances using top search bar</li>
                  <li>Open Appearances page </li>
                  <li>Invited Appearances page</li>
                </ul>
                <p class="mt-4">Searching for Appearances using top search bar - <br />Top search bar allows the Attorney to search for appearances based on Case type and/or State. </p>
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/search-appearance.jpg" class="w-100" /></div>
                <p class="mt-4">
                  Open Appearances page - <br />This shows open appearances matching Attorney’s Practice areas and State of residence (as per the profile information).
                  <br />From here the Attorney can apply for the appearance.
                </p>
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/search-appearance-result.jpg" class="w-100" /></div>
                <p class="mt-4">Invited Appearances page - <br />This lets the Attorney review the Appearances for which s/he is invited to apply for. On the invited appearance, the Attorney can Apply for the appearance, Decline the invitation, Message the Employer and view the Appearance documentation attached. </p>
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/Invited-appearance.jpg" class="w-100" /></div>
              </li>
              <li>
                Once applied, the Attorney can revise the charges (until the application is open, i.e. not approved /  canceled / withdrawn / rejected). The Attorney can also message the Employer or withdraw the application.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/appearance-actions.jpg" class="w-100" /></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            Employer communicates with potential matched Attorneys.
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>
                The employer can communicate with potential matched Attorneys by inviting them to apply or messaging them once they have invited or have applied for the appearance or approving/rejecting their application.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/communication.jpg" class="w-100" /></div>
              </li>
              <li>
                The Msg button on the application lets the Employer chat with the Attorney.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/messages.jpg" class="w-100" /></div>
              </li>
              <li>
                The employer can use the chat functionality to negotiate rates or discuss appearance with the attorney.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/messages-1.jpg" class="w-100" /></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            Employer engages Attorney.
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>
                After communicating with the applied attorneys, the employer can finalize and engage the attorney by approving her/his application.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/approve-attorney.jpg" class="w-100" /></div>
              </li>
              <li>
                While approving, the employer can optionally add a message to the attorney.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/approve-attorney-1.jpg" class="w-100" /></div>
              </li>
              <li>
                The attorney is now approved. <br />Application status, final Hourly rate and link to Attorney profile are now shown on the Filled Appearance card. The employer can further chat with the attorney, share appearance documents or cancel the attorney (up to appearance date).
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/approved-attorney.jpg" class="w-100" /></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            After appearance  is completed, Attorney completes invoice and bills Employer.
          </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>
                The approved attorney would mark the appearance as complete, message the employer, upload appearance documents and raise invoice.
                <div class="col-md-4 mt-4" style="margin:auto;"><img src="assets/img/howitworks/approve-appearances.jpg" class="w-100" /></div>
                <p class="mt-4">Marking appearance complete - </p>
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/making-appearance-completed.jpg" class="w-100" /></div>
                <p class="mt-4">Uploading appearance documents - </p>
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/upload-documents.jpg" class="w-100" /></div>
                <p class="mt-4">Raising the invoice - </p>
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/raise-invoice.jpg" class="w-100" /></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingSeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            Employer pays invoice and Attorney is paid.
          </button>
        </h2>
        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ol>
              <li>
                The Dashboard highlights Due invoices for the employer or s/he can use the menu to go to Due invoices page.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/due-invoices.jpg" class="w-100" /></div>
              </li>
              <li>
                Due invoices will be auto-debited from the employer's account on the due date (auto-debit payment method is required to be set up in the profile immediately on signing up on Videtur).
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/auto-debit.jpg" class="w-100" /></div>
              </li>
              <li>
                The invoice will then be marked as paid.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/paid-invoices.jpg" class="w-100" /></div>
              </li>
              <li>
                If for any reason the auto-debit fails, the employer can pay the invoice manually as well.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/manual-payment.jpg" class="w-100" /></div>
              </li>
              <li>
                System allows the employer to make payment using standard payment methods of Cards.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/select-payment-method.jpg" class="w-100" /></div>
              </li>
              <li>
                The attorney is notified of the payment and can see paid invoices via the dashboard or paid invoice menu page.
                <div class="col-md-6 mt-4" style="margin:auto;"><img src="assets/img/howitworks/paid-invoice-view.jpg" class="w-100" /></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Page Content -->
