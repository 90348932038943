import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertifyService } from 'src/app/services/alertify.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.css']
})
export class UserWalletComponent implements OnInit {
  private iPayoutMemberLoginUrl = environment.iPayoutMemberLoginUrl;
  public userMasterId: any;
  public loginKey: any;

  constructor(private route: ActivatedRoute,private renderer: Renderer2, private spinner: NgxSpinnerService,public alert: AlertifyService, public userService: UserService) { }

  ngOnInit() {
    this.spinner.show();  

    this.userMasterId = this.route.snapshot.params.userMasterId;
    if (this.userMasterId != undefined && this.userMasterId != null) {
      this.userService.AutoLoginWalletDetails(this.userMasterId).subscribe(result => {
      if (result.model != null && result.model != '') {
          //window.location.href = result.model;    
          //this.spinner.hide();
      this.loginKey = result.model;
                //window.open(result.model, '_blank');
                // Create the form element
          const form = this.renderer.createElement('form');
          this.renderer.setProperty(form, 'id', 'theForm');
          this.renderer.setProperty(form, 'action', this.iPayoutMemberLoginUrl);
          this.renderer.setProperty(form, 'method', 'post');

          // Create the hidden input element
          const input = this.renderer.createElement('input');
          this.renderer.setProperty(input, 'type', 'hidden');
          this.renderer.setProperty(input, 'name', 'secKey');
          this.renderer.setProperty(input, 'value', this.loginKey);

          // Append the input element to the form
          this.renderer.appendChild(form, input);

          // Append the form to the body
          this.renderer.appendChild(document.body, form);

          // Submit the form
          form.submit();
      }
      else {
          this.spinner.hide();  
      }
    });
  }
}


}
