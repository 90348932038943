<!-- ======= Breadcrumbs ======= -->
<section class="breadcrumbs mb-3">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h1>FREQUENTLY ASKED QUESTIONS</h1>
      <ol>
        <li><a href="index.html">Home</a></li>
        <li>FREQUENTLY ASKED QUESTIONS</li>
      </ol>
    </div>
  </div>
</section>
<!-- End Breadcrumbs -->
<!-- ======= Page Content ======= -->
<section>
  <div class="container mb-3">
    <div class="row">
      <!-- General FAQ's Start -->
        <h2>GENERAL</h2>
        <hr/>
        <div class="accordion" id="generalAccordation">
          <div class="accordion-item">
              <h2 class="accordion-header" id="headinggeneralQ1">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeneralQ1" aria-expanded="false" aria-controls="collapseGeneralQ1" id="btncollapseGeneralQ1">
                    How is Videtur different than other appearance facilitator platforms?
                  </button>
              </h2>
              <div id="collapseGeneralQ1" class="accordion-collapse collapse" aria-labelledby="headinggeneralQ1"
                  data-bs-parent="#generalAccordation">
                  <div class="accordion-body">
                      <div class="row">
                        Videtur lets you do the choosing!  Most web-based appearance facilitator platforms have a “black box” approach to finding and verifying attorneys. But Videtur puts you in control, letting you scan our database for the right fit. Our roster of capable attorneys is growing rapidly, so it pays to check our site often!
                      </div>
                  </div>
              </div>
          </div>
          <div class="accordion-item">
              <h2 class="accordion-header" id="headingGeneralQ2">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeneralQ2" aria-expanded="false" aria-controls="collapseGeneralQ2" id="btncollapseGeneralQ2">
                      What are the fees?
                  </button>
              </h2>
              <div id="collapseGeneralQ2" class="accordion-collapse collapse" aria-labelledby="headingGeneralQ2" data-bs-parent="#generalAccordation">
                  <div class="accordion-body">
                      <div class="row">
                        There are no fees to sign up. Upon fulfillment of a posted assignment, we do charge a small finder's fee to the hiring attorney or manager (typically equal to 25% of the set payment to the hired attorney).
                      </div>
                  </div>
              </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingGeneralQ3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeneralQ3" aria-expanded="false" aria-controls="collapseGeneralQ3" id="btncollapseGeneralQ3">
                    How do I reach out for more information?
                </button>
            </h2>
            <div id="collapseGeneralQ3" class="accordion-collapse collapse" aria-labelledby="headingGeneralQ3" data-bs-parent="#generalAccordation">
                <div class="accordion-body">
                    <div class="row">
                      Videtur would love to hear from you! Please email us with your questions at <a href="mailto:info@videturpro.com">info@videturpro.com</a> 
                      <!--. Additionally, you can call us at ——— from 8am@ to 5pm PST Monday through Friday. -->
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="m-2"></div>
      <!-- General FAQ's End -->
      <!-- Hiring Arronery FAQ's Start -->

      <h2>HIRING ATTORNEYS and OTHER INTERESTED HIRING MANAGERS</h2>
      <hr/>
      <div class="accordion" id="hiringAttorneyAccordation">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headinghiringAttorneyQ1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsehiringAttorneyQ1" aria-expanded="false" aria-controls="collapsehiringAttorneyQ1" id="btncollapsehiringAttorneyQ1">
                  Are requests only available for appearances?
                </button>
            </h2>
            <div id="collapsehiringAttorneyQ1" class="accordion-collapse collapse" aria-labelledby="headinghiringAttorneyQ1"
                data-bs-parent="#hiringAttorneyAccordation">
                <div class="accordion-body">
                    <div class="row">
                      In addition to appearances, requests can be made for depositions as well as more long-term freelance engagements.
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headinghiringAttorneyQ2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsehiringAttorneyQ2" aria-expanded="false" aria-controls="collapsehiringAttorneyQ2" id="btncollapsehiringAttorneyQ2">
                    How / when do I get charged?
                </button>
            </h2>
            <div id="collapsehiringAttorneyQ2" class="accordion-collapse collapse" aria-labelledby="headinghiringAttorneyQ2" data-bs-parent="#hiringAttorneyAccordation">
                <div class="accordion-body">
                    <div class="row">
                      Unlike other web-based appearance facilitator services, Videtur does not charge any upfront fees to sign up. You don’t get charged until an assignment is completed. You set the hourly rate, and Videtur charges a small finder’s fee (typically equal to 25% of the set payment to the hired attorney).
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headinghiringAttorneyQ3">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsehiringAttorneyQ3" aria-expanded="false" aria-controls="collapsehiringAttorneyQ3" id="btncollapsehiringAttorneyQ3">
                  How am I kept notified as to the status of the requested assignments?
              </button>
          </h2>
          <div id="collapsehiringAttorneyQ3" class="accordion-collapse collapse" aria-labelledby="headinghiringAttorneyQ3" data-bs-parent="#hiringAttorneyAccordation">
              <div class="accordion-body">
                  <div class="row">
                    You will be notified by the hired attorney when the assignment is completed.
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="m-2"></div>
    <!-- Hiring Arronery FAQ's End -->
    <!-- Jobseeker FAQ's Start -->
        <h2>JOB SEEKERS</h2>
        <hr/>
        <div class="accordion" id="JobSeekerAccordation">
          <div class="accordion-item">
              <h2 class="accordion-header" id="headingJobSeekerQ1">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJobSeekerQ1" aria-expanded="false" aria-controls="collapseJobSeekerQ1" id="btncollapseJobSeekerQ1">
                    How do I make contact with hiring attorneys and other hiring managers?
                  </button>
              </h2>
              <div id="collapseJobSeekerQ1" class="accordion-collapse collapse" aria-labelledby="headingJobSeekerQ1"
                  data-bs-parent="#JobSeekerAccordation">
                  <div class="accordion-body">
                      <div class="row">
                        Upon signing up, you can search posted assignments to see what fits. Additionally, hiring attorneys and managers can reach out to you directly when they feel, based upon your completed profile, that you are a match!
                      </div>
                  </div>
              </div>
          </div>
          <div class="accordion-item">
              <h2 class="accordion-header" id="headingJobSeekerQ2">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJobSeekerQ2" aria-expanded="false" aria-controls="collapseJobSeekerQ2" id="btncollapseJobSeekerQ2">
                      How do I get paid?
                  </button>
              </h2>
              <div id="collapseJobSeekerQ2" class="accordion-collapse collapse" aria-labelledby="headingJobSeekerQ2" data-bs-parent="#JobSeekerAccordation">
                  <div class="accordion-body">
                      <div class="row">
                        Upon fulfillment of an assignment, you will update the posting through Videtur’s platform to show the job was completed. This will generate an invoice for the hiring attorney or manager to promptly pay you by the due date listed on the invoice. Assignments are hourly-based (with a minimum of one hour) with rates set by the hiring attorney or manager. Finder’s fees charged by Videtur only apply to hiring attorneys and managers.
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="m-2"></div>
      <!-- Jobseeker FAQ's End -->
    </div>
  </div>
</section>
<!-- End Page Content -->
