<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content">
      <div class="modal-header border-bottom-0">
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
          <div class="form-title text-center">
              <h4><img src="assets/img/Videtur_Logo.png" /></h4>
          </div>
          <div class="d-flex flex-column text-center">
              <form #ForgotPassword='ngForm' (ngSubmit)="onSubmit(ForgotPassword)">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Your email address..." #LoginId="ngModel" required ngModel name="LoginId">
                    <span *ngIf="!LoginId.valid && LoginId.touched" class="error-block">Please provide login ID.</span>
                  </div>
                  <button [disabled]="!ForgotPassword.valid" type="submit" class="btn btn-success btn-block btn-round">Get New Password</button>
              </form>
          </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
          <div class="signup-section">Already have an account? <a href="#" class="text-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#loginModal" (click)="activeModal.dismiss('Cross click');openLoginPopup('1');"> Sign In</a>.</div>
      </div>
  </div>
</div>
