<!-- ======= Breadcrumbs ======= -->
<section class="breadcrumbs mb-3">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Terms of Service</h1>
      <ol *ngIf="!fromSignUp">
        <li><a href="index.html">Home</a></li>
        <li>Terms of Service</li>s
      </ol>
      <button *ngIf="fromSignUp" type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
</section>
<!-- End Breadcrumbs -->
<!-- ======= Page Content ======= -->
<section>
  <div class="container mb-3">
    <div class="row">
      <p>Last updated: <b><span >December 21</span>, 2023</b></p>
      <p>Please read these Terms of Service carefully before using Our Service.</p>
      <h2>Definitions</h2>
      <hr />
      <p>For the purposes of these Terms of Service:</p>
      <p>
        <b>Account</b> means a unique account created for You to access our Service or parts of our Service.<br />
        <b>Country</b> refers to United States<br />
        <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Videtur LLC, Videtur, Videtur Pro, 75 Broadway Ste 202, San Francisco, CA 94111.<br />
        <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.<br />
        <b>Service</b> refers to the Website.<br />
        <b>Terms of Service</b> (also referred as "Terms") mean these Terms of Service that form the entire agreement between You and the Company regarding the use of the Service.<br />
        <b>Third-party Social Media Service</b> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.<br />
        <b>Website</b> refers to Videtur LLC or Videtur, accessible from <a href="https://www.videturpro.com" target="_blank">www.videturpro.com</a><br />
        <b>You</b> is the individual accessing or using the Service, business, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.<br />
        <b>User</b> Any individual, Attorney, or business accessing or using the Service.<br />
        <b>Appearance</b> Occurs when a licensed attorney physically or remotely appears in court on behalf of a party to a lawsuit, or to a formal act.<br />
        <b>A Hiring Attorney</b> is the individual who has a valid retainer agreement executed with the client for which an appearance is at issue.<br />
        <b>Attorney</b> An actively licensed attorney who is a member in good standing with a state bar, who is available and/or has agreed to make the subject appearance, on behalf of the attorney of record to participate in the court process.
      </p>
      <h2>Acknowledgment</h2>
      <hr />
      <p>These are the Terms of Service governing the use of this Service and the agreement that operates between You and the Company. These Terms of Service set out the rights and obligations of all users regarding the use of the Service.</p>
      <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms of Service. These Terms of Service apply to all visitors, users and others who access or use the Service.</p>
      <p>By accessing or using the Service You agree to be bound by these Terms of Service. If You disagree with any part of these Terms of Service, then You may not access the Service.</p>
      <p>You represent that you are over the age of 18, An attorney admitted to practice in good standing with your respective bar or a legal professional in good standing, a law firm, legal department, and/ or a business entity wishing to use this service to retain or be hired for an appearance in accordance with this Terms of Service Agreement. Videtur LLC does not permit those under 18 to use the Service.</p>
      <p>ONLY ACTIVE LICENSED ATTORNEYS WHO ARE MEMBERS IN GOOD STANDING WITH THEIR RESPECTIVE STATE LICENSING AUTHORITY ARE ELIGIBLE TO REGISTER AS ATTORNEYS.</p>
      <p>Should Attorney become sanctioned or disciplined by any licensing authority or becomes ineligible to practice law for any reason, Attorney must proactively update any pending disciplinary action User’s account and not accept any posted Appearance by the Hiring Attorney.</p>
      <p>User is responsible for performing obligations as specified by this Terms of Service and agreements that User enter into through this website when not in conflict with this Terms of Service or prohibited by law. User acknowledges that Videtur LLC shall have no liability to User for any conduct, statements or representations made by the User as a result of use of Videtur LLC’s Service(s).</p>
      <p>You MAY NOT USE the Service if You are a recruiter or a staffing agency, including attorneys that are engaged in the businesses of staffing employees, or otherwise a third party to the retaining or hiring of attorneys unless expressly permitted by Videtur LLC. Legal actions and damages will be pursued for violations of this term.</p>
      <p><b>For the duration of this agreement and for six (6) months following User’s most recent visit to Videutur's website, User is not allowed to directly or indirectly contact, solicit, or induce other Users to engage in services unrelated to the provided services, unless specifically permitted by these terms. Any User who violates this agreement and contacts another User during the prohibited period will be required to pay a fee of $3000.</b></p>
      <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
      <p>User MUST NOT:</p>
      <ul class="ms-5">
        <li>Use this Service to explore the software Service for the purpose of reproducing, replicating, selling, or exploiting any portion of the Service, or otherwise copying the software Service and business model.</li>
        <li>Register to use this Service if the Attorney is disbarred for disciplinary action.</li>
        <li>Post or transmit unsolicited e-mail, SMS, Instant Messenger, Twitter or other spam messages, or viruses.</li>
        <li>Use this service for any illegal or unauthorized purpose.</li>
      </ul>
      <h2>About The Service</h2>
      <hr />
      <p>By using Videtur LLC’s website and related services, You warrant that You are legally capable of entering into binding contracts.</p>
      <p>Videtur LLC is a software as a service (SaaS) platform that enables individuals, businesses, law firms, and attorneys to connect instantaneously to complete specific legal services for each other. Nothing on this site shall be construed as legal advice from Videtur LLC. Videtur LLC is not authorized to provide legal advice.</p>
      <p class="text-primary"><b>User Accounts</b></p>
      <p>When You create an account with Videtur LLC , You must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms of Service, which may result in immediate termination of Your account on Our Service.</p>
      <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
      <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
      <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
      <p class="text-primary"><b>Your Information</b></p>
      <p>If You wish to place an appearance using this Service through Videtur LLC’s Website, You may be asked to supply certain information relevant to Your posing including, without limitation, Your name, Your email, Your phone number, Your physical and mailing address, Your state bar license information, Your credit card number, the expiration date of Your credit card, and Your billing address.</p>
      <p>You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) including Automated Clearing House (ACH) in connection with any Service; and that (ii) the information You supply to us is true, correct and complete.</p>
      <p>By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Service request(s).</p>
      <p class="text-primary"><b>Service Cancellation</b></p>
      <p>Videtur LLC reserves the right to refuse or cancel Your Service Request at any time for certain reasons including but not limited to fraud or an unauthorized or illegal transaction is suspected.</p>
      <p>Users can cancel a Service request or Appearance that is not yet completed. Any posted Appearance that is cancelled by the Hiring Attorney is subject to at least a minimum one hourly rate of the full contracting Service charge if cancelled within 24 clock hours on a day before the date of the hearing, including Videtur LLC’s finder’s fee and costs of the Service charge.</p>
      <p>Any Attorney, upon acceptance of Appearance assignment shall be solely responsible for their performance and conduct in carrying out their assigned Appearance. User agrees to indemnify and hold Videtur LLC harmless from all alleged claims in the event of Attorney’s failure to complete the Appearance assignment, as well as any error or mistake on Attorney’s part.</p>
      <p class="text-primary"><b>Communications</b></p>
      <p>Unless User otherwise opt-out from receiving notices, Videtur LLC will communicate with User by email or by posting communications on Videtur’s website. User consent to receive communications from Videtur LLC electronically and User agrees that these electronic communications satisfy any legal requirement that such communications be in writing. You will be considered to have received communication when Videtur LLC sends it to the email address you have provided on your User’s account in Videtur LLC’s site, or when Videtur LLC posts such communication on the site. User must keep your email address updated on this Site and you must regularly check this Site for postings.</p>
      <p class="text-primary"><b>Payments</b></p>
      <p>Videtur LLC is in the business of providing Services. In that case, Videtur LC may use third-party services for payment processing (e.g. payment processors).</p>
      <p>Videtur LLC will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
      <p>Videtur LLC does not charge advance fees to sign up. Hiring Attorneys set the hourly rate for each posted Appearance assignment.  Upon completion of Appearance assignment, the Attorney will update the posting through Videtur’s platform to show the assignment was completed. An invoice will be generated for the Hiring Attorney. The Attorney will be paid within the specified due date on the invoice. The Hiring Attorney will be charged a nominal finder's fee by Videtur LLC. Videtur charges a finder's fee or costs of twenty-five percent (25%), which is paid by the Hiring Attorney on each assigned Appearance. </p>
      <p>Appearance assignments are hourly-based with a minimum of one (1) hour. The hourly rates are set by the Hiring Attorney.</p>
      <p>Videtur LLC reserves the right to revise its finder’s fee at any time by posting a notice on Videtur’s website or by sending You an email.</p>
      <p>By using our third-party services for payment processing, User authorizes Videtur LLC to electronically debit or credit User’s account for invoices on or after their due date and, if necessary, electronically credit User’s account to correct erroneous debits.</p>
      <p>All overdue invoices will be charged a reasonable interest rate at the sole discretion of Videtur LLC. If Your invoice is overdue by 45 or more days, Your User account may be disabled until your payment is received. Videtur LLC may refer any past due invoices to any collection agency or attorney, and User will be liable for the payment of all costs and expenses, including reasonable attorneys' fees, associated therewith.</p>
      <h2>Intellectual Property</h2>
      <hr />
      <p>The Service and its original content (excluding Content provided by You or other Users), features and functionality are and will remain the exclusive property of Videtur LLC and its licensors.</p>
      <p>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</p>
      <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>
      <h2>Links to Other Websites</h2>
      <hr />
      <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
      <p>Videtur LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Vidtur LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
      <p>We strongly advise You to read the Terms of Service and privacy policies of any third-party web sites or services that You visit.</p>
      <h2>Termination</h2>
      <hr />
      <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms of Service.</p>
      <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>
      <h2>Limitation of Liability</h2>
      <hr />
      <p>Notwithstanding any damages that You might incur, the entire liability of the Company under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service.</p>
      <p>To the maximum extent permitted by applicable law, in no event shall Videtur LLC be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
      <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
      <h2>Governing Law</h2>
      <hr />
      <p>The laws of state of California, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
      <h2>Disputes Resolution</h2>
      <hr />
      <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute by contacting Videtur LLC.</p>
      <p class="text-primary"><b>Mandatory Arbitration</b></p>
      <p>Any controversy or claim between or among the parties, including those arising out of or relating to this Terms of Service or and any claim based on or arising from an alleged tort, shall at the request of any party be determined by arbitration. The arbitration shall be conducted in San Francisco, California, in accordance with the United States Arbitration Act (Title 9, U.S. Code), notwithstanding any choice of law provision in this Terms of Service, and under the Rules of the American Arbitration Association (the "AAA"). The arbitrator(s) shall give effect to statutes of limitation in determining any claim. Any controversy concerning whether an issue is arbitrable shall be determined by the arbitrator(s). Judgment upon the arbitration award may be entered in any court having jurisdiction. The institution and maintenance of an action for judicial relief or pursuit of a provisional or ancillary remedy shall not constitute a waiver of the right of any party, including the plaintiff, to submit the controversy or claim to arbitration if any other party contests such action for judicial relief.</p>
      <h2>Severability and Waiver</h2>
      <hr />
      <p class="text-primary"><b>Severability</b></p>
      <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
      <p class="text-primary"><b>Waiver</b></p>
      <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
      <h2>Translation Interpretation</h2>
      <hr />
      <p>These Terms of Service may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
      <h2>Changes to These Terms of Service</h2>
      <hr />
      <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
      <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
      <h2>Contact Us</h2>
      <hr />
      <p>If you have any questions about these Terms of Service, You can contact us:</p>
      <p class="ms-5">
        By email: <a mailto="info@videturpro.com">info@videturpro.com</a><br />
        By visiting this page on our website: <a href="https://www.videturpro.com/contact">www.videturpro.com/contact</a>
      </p>
    </div>
  </div>
</section>
<!-- End Page Content -->
