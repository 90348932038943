<div class="card card-secondary bg-light">
  <div class="card-header">
    <h5>Attorney Info</h5>
    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="card-body">
    <div class="form-group">
      <ul>
        <li>Created: Sep/30/2021 By : Nirav</li>
        <li>Invited: Sep/30/2021 By : Nirav</li>
        <li>Approved: Sep/30/2021 By : Nirav</li>
      </ul>
    </div>
  </div>
</div>
