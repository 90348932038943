<div class="card card-primary" >
    <div class="card-header">
        <h3 style="display: inline ;">Make Payment <label class="text-danger">{{totalForPay | currency:'USD':'symbol'}}</label></h3>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn"
                  (click)="activeModal.dismiss('Cross click')"><span aria-hidden="true">×</span>
            </button>
    </div>
    <div class="card-body" style="height: 400px;">
        <div class="row">
            <form id="myForm" name = "myForm">
                <iframe id="myIframe" src="" style ="width: 100%;height: 300px;">
                </iframe>
                <div class="text-center mt-3">
                    <input type="submit" class="btn btn-success" />
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
