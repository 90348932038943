    <!-- ======= Breadcrumbs ======= -->
<section class="breadcrumbs mb-3">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Invoice List that is {{currentURL | lowercase}}</h1>
      <ol>
        <li><a [routerLink]="['/dashboard']">Home</a></li>
        <li>Invoice List that is {{currentURL | lowercase}}</li>
      </ol>
    </div>
  </div>
</section><!-- End Breadcrumbs -->
<section>
  <div class="container mb-3">
    <div class="row">
      <div class="col-md-4" *ngFor="let invoice of invoiceList;">
        <app-invoice-card [invoice]="invoice"></app-invoice-card>
      </div>
    </div>
  </div>
</section>

<app-idle></app-idle>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
