<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content">
      <div class="modal-header border-bottom-0">
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn" (click)="activeModal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
          <div class="form-title text-center">
              <h4><img src="assets/img/Videtur_Logo.png" /></h4>
          </div>
          <div class="d-flex flex-column text-center">
              <form *ngIf="!showOtpForm" [formGroup]="loginForm" (ngSubmit)="onLogin()">
                  <div class="form-group">
                      <input type="email" class="form-control" placeholder="Your email address..." required formControlName="loginId">
                      <span *ngIf="!loginId.valid  && (loginId.errors?.touched || loginId)" class="error-block">Please provide login ID.</span>
                  </div>
                  <div class="form-group">
                      <input type="password" class="form-control" placeholder="Your password..." required formControlName="password">
                      <span *ngIf="!password.valid && (password.touched || userSubmitted)" class="error-block">Please provide password.</span>
                  </div>
                  <button [disabled]="!loginForm.valid" type="submit" class="btn btn-success btn-block btn-round">Sign In</button>
              </form>
              <form *ngIf="showOtpForm" [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()">
                <div class="form-group">
                    <div class="h3">Two-step authentication</div>
                    <span>To continue, please enter the 6-digit verification code sent to your login email.</span>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Your OTP..." formControlName="otp">
                  <span *ngIf="!password.valid && (password.touched || userSubmitted)" class="error-block">Please provide OTP.</span>
                </div>
                <div class="form-group">
                    <button [disabled]="!loginForm.valid" type="submit" class="btn btn-success btn-block btn-round">Continue</button>
                </div>
                
            </form>
            <div *ngIf="showOtpForm" >
                <span>Didn't receive a code? <button class="btn btn-link" (click)="onResend()" >Resend</button></span>
            </div>

              <div class="text-center text-muted delimiter">or use a social network</div>
              <div class="d-flex justify-content-center social-buttons">
                  <!-- <button type="button" class="btn btn-secondary btn-round" (click)="loginWithGoogle()" data-bs-toggle="tooltip" data-placement="top" title="Twitter">
                      <i class="fa fa-google"></i>
                  </button> -->
                  <div id="buttonDiv">

                  </div>
                  <!-- following button commented by atul on 30 july 23 for Facebook login - will give it in next phase -->
                  <!-- <button type="button" class="btn btn-secondary btn-round" (click)="signInWithFB()" data-bs-toggle="tooltip" data-placement="top" title="Facebook">
                      <i class="fa fa-facebook"></i>
                  </button> -->
                  <!-- <button type="button" class="btn btn-secondary btn-round" data-bs-toggle="tooltip" data-placement="top" title="Linkedin">
                      <i class="fa fa-linkedin"></i>
                  </button> -->
              </div>
          </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
          <div class="signup-section">
              Forgot <a href="#" class="text-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#forgotModal" (click)="activeModal.dismiss('Cross click');openForgotPasswordPopup('1');"> Password?</a><br />
              Not a member yet? <a href="#" class="text-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#registerModal"  (click)="activeModal.dismiss('Cross click');openRegisterPopup('1');"> Sign Up</a>.
          </div>
      </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Logining in... </p></ngx-spinner>
