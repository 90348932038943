import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonConstant } from 'src/app/Common/Constants/Constants';
import { AlertifyService } from 'src/app/services/alertify.service';
import { ContactUsService } from 'src/app/services/ContactUs.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public contactUsForm: FormGroup;
  public key:string ='6LfuH4IhAAAAAFsQ7eGhRaGg0_gL6ZaR7FmKLYWz';
  submitted = false;
  submitSuccess = false;
  finalFormDataUpdate: FormData;


  constructor(private fb: FormBuilder, private alertify: AlertifyService, private contactUsService: ContactUsService) { }

  ngOnInit() {
    this.contactUsForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobileNo: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }
  get f() { return this.contactUsForm.controls; }


  handleSuccess(params:any) {
    console.log(params);
  }

    //Following method added by atul on date 9 apr 22 for fix bug 473 Subuser created by Dual role user also gets dual role
    getFormValidationErrors() {
      //Code modified by atul on dated 20 apr 22 for bug 491: Profile - If any mandatory fields are pending, the page should be positioned to show the pending fields
      var iRow = 0;
      Object.keys(this.contactUsForm.controls).forEach(key => {
  
        const controlErrors = this.contactUsForm.get(key)?.errors;
        if (controlErrors != null && iRow==0) {
          iRow++;
          Object.keys(controlErrors).forEach(keyError => {
            if(key == 'recaptcha')
              this.alertify.error("Please verify the captcha.");        
            else
              this.alertify.error(CommonConstant.PROFILE_MESSAGES.ALL_REQUIRED_ERROR);
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            return;
          });
          return;
        }
      });
    }
    
    //Following all functions modified by atul on 12 may 22 for making stripe mandatory
    onSubmit() {
      this.submitted = true;
      //Following line added by atul on date 9 apr 22 for fix bug 473 Subuser created by Dual role user also gets dual role
      this.getFormValidationErrors();
      if (this.contactUsForm.valid) {
        console.log("asb");
      
        this.finalFormDataUpdate = new FormData();
        this.finalFormDataUpdate.append('Name', this.contactUsForm.get('name')?.value);
        this.finalFormDataUpdate.append('Email', this.contactUsForm.get('email')?.value);
        this.finalFormDataUpdate.append('MobileNo', this.contactUsForm.get('mobileNo')?.value);
        this.finalFormDataUpdate.append('Subject', this.contactUsForm.get('subject')?.value);
        this.finalFormDataUpdate.append('Message', this.contactUsForm.get('message')?.value);
         
        this.contactUsService.add(this.finalFormDataUpdate)
         .subscribe(() => {
          this.submitSuccess = true;
          this.alertify.success('Form has been submitted successfully!');
         });
        }
    }
  
}
