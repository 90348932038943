<div class="card card-info">
  <div class="card-header">
    <h5>Invite Attorney</h5>
    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
    <div class="card-body">
      <div *ngIf="AttorneyList.length > 1" class="attorney-list">
        <div class="form-group">
          <table class="table table-bordered">
            <thead>
              <tr>
                <!-- <th style="width: 5px;"></th> -->
                <th style="width: 85px;">Selected Attorney</th>
                <th style="width: 10px;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let attorney of AttorneyList">
                <!-- <td><input type="checkbox"></td> {{attorney.lastName}} -->
                <td>{{attorney.firstName}} *****</td>
                <td><span class="pointer text-danger"><i class="fa fa-trash-o" (click)="DeleteAttornies(attorney)"></i></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group">
        <label>Message</label>
        <textarea class="form-control" [(ngModel)]="Message" row="3"></textarea>
      </div>
    </div>
    <div class="card-footer text-center">
      <button type="submit" (click)="inviteAttorney()" class="btn btn-info">Invite</button>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>