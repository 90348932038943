<div class="card card-secondary">
  <div class="card-header">
    <h5>Documents</h5>
    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="closeBtn"
      (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form>
    <div class="card-body">
      <div class="form-group">
        <table *ngIf="DocList?.length>0" class="table table-bordered">
          <thead>
            <tr>
              <th>File Name</th>
              <th>File Type</th>
              <th *ngIf="profileType!= 'A'">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of DocList">
              <td> <p (click)="DownloadDocs(doc.relativePath, doc.name)">{{doc.name}}</p></td>
              <td>{{doc.name.split(".").pop()}}</td>
              <td *ngIf="profileType!= 'A'"><span class="pointer text-danger"><i class="fa fa-trash-o" (click)="DeleteFile(doc)"></i></span></td>
            </tr>
          </tbody>

        </table>
      </div>
      <div class="form-group">
        <div class="input-group">
          <label for="exampleFormControlTextarea1">Upload Documents : </label>&nbsp;&nbsp;
          <input type="file" class="form-control-file" multiple (change)="uploadDocument($event)"
            id="exampleFormControlFile1">
          <!-- <span class="upload" *ngIf="progress > 0">
            {{progress}}%
          </span> -->
        </div>
      </div>
    </div>
    <div class="card-footer text-center">
      <button type="submit" class="btn btn-secondary" (click)="Save()">Save Changes</button>
    </div>
    <div *ngIf="DocList?.length == 0">No document Available !</div>
    <div class="form-group text-center">
      <small class="label-static-msg">Allowed Types: doc, docx, xlsx, pdf, jpg, jpeg, zip, png, txt</small>
    </div>
  </form>

</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>